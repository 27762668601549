import { SET_ACCOUNT, REMOVE_ACCOUNT } from "src/actions/accountActions";

// const initialState = {
//    blockedBy: "",
//    createdAt: "",
//    isBlocked: false,
//    login_attempts: 0,
//    login_counts: 0,
//    updatedAt: "",
//    userType: null,
//    username: "",
//    _id: "",
// };

const initialState = null;

export const accountReducer = (state = initialState, action) => {
   switch (action.type) {
      case SET_ACCOUNT:
         return { ...action.payload.account };

      case REMOVE_ACCOUNT:
         return initialState;

      default:
         return state;
   }
};
