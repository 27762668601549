import { makeStyles } from "@material-ui/core/styles";
import CircularProgress from "@material-ui/core/CircularProgress";

const useStyles = makeStyles((theme) => ({
   root: {
      display: "flex",
      "& > * + *": {
         marginLeft: theme.spacing(2),
      },
      justifyContent: "center",
      alignItems: "center",
      height: "88vh",
      width: "100%",
      background: "#f4f6f8",
   },
}));

const LoadingScreen = () => {
   const classes = useStyles();

   return (
      <div className={classes.root}>
         <CircularProgress />
      </div>
   );
};
export default LoadingScreen;
