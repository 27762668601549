import { useContext } from "react";
import { AccountContext } from "src/context/AccountContextProvider";
import { SET_ACCOUNT, REMOVE_ACCOUNT } from "src/reducers/accountReducer";

export default function useAccount() {
   const { account, dispatch } = useContext(AccountContext);

   // let localAccount = null;
   // if (!account) {
   //    localAccount = localStorage.getItem("account");

   //    if (localAccount) {
   //       //You can alos user API call here...
   //       localAccount = JSON.parse(localAccount);
   //       dispatch({
   //          type: SET_ACCOUNT,
   //          payload: {
   //             account: localAccount,
   //          },
   //       });
   //    }
   // }

   // const logout = async () => {
   //    //Some API call for logout
   //    dispatch({ type: REMOVE_ACCOUNT });
   // };

   // const login = async () => {};

   //Create account context related functios here and use it any where in you code

   // return { account: account ? account : localAccount, logout, login };
   return [account, dispatch];
}
