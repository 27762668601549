import Config from "src/constants/Config";

let IPAddress = Config.IPAddress + "/admin/owner/";

const AdminOwnerServices = {
   getOwners: IPAddress + "getAllOwners",
   getOwnerById: IPAddress + "getOwnerById",
   getChildOwnersByOwnerId: IPAddress + "getChildOwnersByOwnerId",
   createOwner: IPAddress + "createOwner",
   searchOwner: IPAddress + "searchOwner",
   getWithdrawalRequests: IPAddress + "getWithdrawalRequests",
   approveWithdrawalRequest: IPAddress + "approveWithdrawalRequest",
   cancelWithdrawalRequest: IPAddress + "cancelWithdrawalRequest",
};

export default AdminOwnerServices;
