import PropTypes from "prop-types";
import { Redirect } from "react-router-dom";
import { useAccount } from "src/hooks";

function AuthGuard({ children }) {
   const [account] = useAccount();

   if (!account) {
      return <Redirect to="/logout" />;
   }
   return children;
}

AuthGuard.propTypes = {
   children: PropTypes.any,
};

export default AuthGuard;
