import { createContext, useReducer } from "react";
import { accountReducer } from "src/reducers/accountReducer";

export const AccountContext = createContext();

const AccountContextProvider = ({ children }) => {
   const [account, dispatch] = useReducer(accountReducer);

   return (
      <AccountContext.Provider value={{ account, dispatch }}>
         {children}
      </AccountContext.Provider>
   );
};

export default AccountContextProvider;
