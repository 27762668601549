import {
   SET_OWNER_LIST_INITIAL_WITH_ERROR,
   SET_OWNER_LIST_LOADING_ACTIVE,
   SET_OWNER_LIST_LOADING_INACTIVE,
   SET_OWNER_LIST,
   SET_OWNER_LIST_SHOW_ALL,
} from "src/actions/admin/ownerActions";
import { initialState } from "src/context/OwnerListContextProvider";

export const ownerListReducer = (state, action) => {
   switch (action.type) {
      case SET_OWNER_LIST_INITIAL_WITH_ERROR:
         return { ...initialState, error: true };

      case SET_OWNER_LIST_LOADING_ACTIVE:
         return { ...state, isLoading: true };

      case SET_OWNER_LIST_LOADING_INACTIVE:
         return { ...state, isLoading: false };

      case SET_OWNER_LIST:
         return {
            ...state,
            owners: action.payload.owners,
            isLoading: action.payload.isLaoding,
            error: false,
            showAllOwners: false,
         };

      case SET_OWNER_LIST_SHOW_ALL:
         return {
            ...state,
            showAllOwners: true,
         };

      default:
         return state;
   }
};
