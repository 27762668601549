import Axios from "src/config/Axios";
import AdminOwnerServices from "src/services/admin/OwnerServices";

// Owner List actions
export const SET_OWNER_LIST_INITIAL_WITH_ERROR = "@owner-list/set-initial";
export const SET_OWNER_LIST_LOADING_ACTIVE = "@owner-list/set-loading-active";
export const SET_OWNER_LIST_LOADING_INACTIVE =
   "@owner-list/set-loading-inactive";
export const SET_OWNER_LIST = "@owner-list/set";
export const SET_OWNER_LIST_SHOW_ALL = "@owner-list/show-all-owners";

// Owner Profile actions
export const SET_OWNER_PROFILE = "owner-profile/set";
export const SET_OWNER_PROFILE_INITIAL_WITH_ERROR =
   "@owner-profile/set-initial";
export const SET_OWNER_PROFILE_LOADING_ACTIVE =
   "@owner-profile/set-loading-active";
export const SET_OWNER_PROFILE_LOADING_INACTIVE =
   "@owner-profile/set-loading-inactive";
export const UPDATE_VEHICLE_OWNER_PROFILE = "@owner-profile/update-vehicle";
export const ADD_VEHICLE_OWNER_PROFILE = "@owner-profile/add-vehicle";
export const ADD_DRIVER_OWNER_PROFILE = "@owner-profile/add-driver";
export const UPDATE_DRIVER_OWNER_PROFILE = "@owner-profile/update-driver";

// Owner withdrawal request  actions
export const SET_OWNER_WITHDRAWAL_REQUEST_INITIAL_WITH_ERROR =
   "@owner-withdrawal-request/set-initial";
export const SET_OWNER_WITHDRAWAL_REQUEST_LOADING_ACTIVE =
   "@owner-withdrawal-request/set-loading-active";
export const SET_OWNER_WITHDRAWAL_REQUEST = "@owner-withdrawal-request/set";
export const SET_OWNER_WITHDRAWAL_REQUEST_EDIT =
   "@owner-withdrawal-request/set-edit";
export const ADD_OWNER_WITHDRAWAL_REQUEST = "@owner-withdrawal-request/set-add";
export const REMOVE_OWNER_WITHDRAWAL_REQUEST =
   "@owner-withdrawal-request/set-remove";

export function getOwners() {
   let request = Axios.get(AdminOwnerServices.getOwners);
   return request
      .then((result) => {
         return result.data;
      })
      .catch((error) => {
         return error.response.data;
      });
}

export function getOwnerById(ownerId) {
   let request = Axios.get(`${AdminOwnerServices.getOwnerById}/${ownerId}`);
   return request
      .then((result) => {
         return result.data;
      })
      .catch((error) => {
         return error.response.data;
      });
}

export function getChildOwnersByOwnerId(ownerId) {
   let request = Axios.get(
      `${AdminOwnerServices.getChildOwnersByOwnerId}/${ownerId}`
   );
   return request
      .then((result) => {
         return result.data;
      })
      .catch((error) => {
         return error.response.data;
      });
}

export function createOwner(ownerProfile) {
   let request = Axios.post(AdminOwnerServices.createOwner, {
      ...ownerProfile,
   });
   return request
      .then((result) => {
         return result.data;
      })
      .catch((error) => {
         return error.response.data;
      });
}

export function searchOwner(searchQuery) {
   let request = Axios.post(AdminOwnerServices.searchOwner, {
      searchQuery,
   });
   return request
      .then((result) => {
         return result.data;
      })
      .catch((error) => {
         return error.response.data;
      });
}

export function getWithdrawalRequests(status, limit = 0, order = "desc") {
   let request = Axios.get(
      `${AdminOwnerServices.getWithdrawalRequests}/${status}/${limit}/${order}`
   );
   return request
      .then((result) => {
         return result.data;
      })
      .catch((error) => {
         return error.response.data;
      });
}

export function approveWithdrawalRequest(
   requestId,
   orderId,
   paymentId,
   transactionDate
) {
   console.log("log: aa", requestId, orderId, paymentId, transactionDate);
   let request = Axios.post(AdminOwnerServices.approveWithdrawalRequest, {
      requestId,
      orderId,
      paymentId,
      transactionDate,
   });
   return request
      .then((result) => {
         return result.data;
      })
      .catch((error) => {
         return error.response.data;
      });
}

export function cancelWithdrawalRequest(requestId, reason) {
   let request = Axios.patch(AdminOwnerServices.cancelWithdrawalRequest, {
      requestId,
      reason,
   });
   return request
      .then((result) => {
         return result.data;
      })
      .catch((error) => {
         return error.response.data;
      });
}
