import React, { lazy, Suspense, Fragment } from "react";
import { Switch, Route, Redirect } from "react-router-dom";
import LoadingScreen from "src/components/LodingScreen";
import AuthGuard from "./components/AuthGuard";
import GuestGuard from "./components/GuestGuard";
import Config from "./constants/Config";
import DashboardLayout from "./layouts/DashboardLayout";

const adminRoutesConfig = [
   {
      exact: true,
      path: "/admin",
      component: () => <Redirect to="/admin/dashboard" />,
   },
   {
      exact: true,
      path: "/admin/dashboard",
      component: lazy(() => import("src/views/admin/Dashboard")),
   },
   {
      exact: true,
      path: "/admin/riders",
      component: lazy(() => import("src/views/admin/Rider")),
   },
   {
      exact: true,
      path: "/admin/riders/:riderId",
      component: lazy(() => import("src/views/admin/Rider/Profile")),
   },
   {
      exact: true,
      path: "/admin/owners",
      component: lazy(() => import("src/views/admin/Owner")),
   },
   {
      exact: true,
      path: "/admin/owners/:ownerId",
      component: lazy(() => import("src/views/admin/Owner/Profile")),
   },
   {
      exact: true,
      path: "/admin/drivers",
      component: lazy(() => import("src/views/admin/Driver")),
   },
   {
      exact: true,
      path: "/admin/drivers/:driverId",
      component: lazy(() => import("src/views/admin/Driver/Profile")),
   },
   {
      exact: true,
      path: "/admin/vehicles",
      component: lazy(() => import("src/views/admin/Vehicle")),
   },
   {
      exact: true,
      path: "/admin/vehicles/:vehicleId",
      component: lazy(() => import("src/views/admin/Vehicle/Profile")),
   },
   {
      exact: true,
      path: "/admin/rides",
      component: lazy(() => import("src/views/admin/Ride")),
   },
   {
      exact: true,
      path: "/admin/fairAmount",
      component: lazy(() => import("src/views/admin/FairAmount")),
   },
   {
      exact: true,
      path: "/admin/onwer-withdrawal-requests",
      component: lazy(() => import("src/views/admin/Owner/WithdrawalRequest")),
   },
   {
      exact: true,
      path: "/admin/ride-feedback",
      component: lazy(() => import("src/views/admin/RideFeedback")),
   },
   {
      exact: true,
      path: "/admin/route",
      component: lazy(() => import("src/views/admin/Route")),
   },
   {
      component: () => <Redirect to="/404" />,
   },
];

const routesConfig = [
   {
      exact: true,
      guard: GuestGuard,
      path: "/",
      component: Config.showHome
         ? lazy(() => import("src/views/pages/Home"))
         : () => <Redirect to="/admin/login" />,
   },
   // {
   //    exact: true,
   //    guard: GuestGuard,
   //    path: "/login",
   //    component: lazy(() => import("src/views/auth/LoginView")),
   // },
   {
      exact: true,
      guard: GuestGuard,
      path: "/logout",
      component: lazy(() => import("src/views/auth/Logout")),
   },
   {
      exact: true,
      guard: GuestGuard,
      path: "/privacyPolicy",
      component: lazy(() => import("src/views/pages/PrivacyPolicy")),
   },
   {
      exact: true,
      guard: GuestGuard,
      path: "/termsAndConditions",
      component: lazy(() => import("src/views/pages/TermAndConditions")),
   },
   {
      exact: true,
      guard: GuestGuard,
      path: "/admin/login",
      component: lazy(() => import("src/views/admin/Auth/LoginView")),
   },
   {
      path: "/admin",
      guard: AuthGuard,
      layout: DashboardLayout,
      routes: adminRoutesConfig,
   },
   {
      exact: true,
      guard: GuestGuard,
      path: "/404",
      component: lazy(() => import("src/views/pages/PageNotFound")),
   },
   {
      component: () => <Redirect to="/404" />,
   },
];

const renderRoutes = (routes) =>
   routes ? (
      <Suspense fallback={<LoadingScreen />}>
         <Switch>
            {routes.map((route, i) => {
               const Guard = route.guard || Fragment;
               const Layout = route.layout || Fragment;
               const Component = route.component;

               return (
                  <Route
                     key={i}
                     path={route.path}
                     exact={route.exact}
                     render={(props) => (
                        <Guard>
                           <Layout>
                              {route.routes ? (
                                 renderRoutes(route.routes)
                              ) : (
                                 <Component {...props} />
                              )}
                           </Layout>
                        </Guard>
                     )}
                  />
               );
            })}
         </Switch>
      </Suspense>
   ) : null;

function Routes() {
   return renderRoutes(routesConfig);
}

export default Routes;
