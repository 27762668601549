import PropTypes from "prop-types";
import { Redirect } from "react-router-dom";

function GuestGuard({ children }) {
   let account = false;
   if (account) {
      return <Redirect to="/admin" />;
   }

   return children;
}
GuestGuard.propTypes = {
   children: PropTypes.any,
};

export default GuestGuard;
