import PropTypes from "prop-types";
import { useState, useEffect } from "react";
import { useContext } from "react";
import AuthService from "src/services/AuthService";
import { Redirect } from "react-router-dom";
import { loginWithToken, SET_ACCOUNT } from "src/actions/accountActions";
import LoadingScreen from "./LodingScreen";
import { AccountContext } from "src/context/AccountContextProvider";

function Auth({ children }) {
   const { dispatch } = useContext(AccountContext);
   const [isLoading, setIsLoading] = useState(true);

   const initAuth = async () => {
      const accessToken = await AuthService.getAccessToken();

      // Check if token is there and token is valid!
      if (!accessToken || !AuthService.isValidToken(accessToken)) {
         console.log("log: token not found!", accessToken);
         setIsLoading(false);
         return <Redirect to="/logout" />;
      }

      const account = await AuthService.getAccount();
      if (!account) {
         loginWithToken().then((response) => {
            setIsLoading(false);
            if (!response) {
               return <Redirect to="/logout" />;
            }

            if (response.error) {
               return <Redirect to="/logout" />;
            }
            AuthService.setSession(response.accessToken, response.user);

            dispatch({
               type: SET_ACCOUNT,
               payload: { account: response.user },
            });
            return;
         });
      }

      // Set Token and account if found
      AuthService.setSession(accessToken, account);
      dispatch({
         type: SET_ACCOUNT,
         payload: { account },
      });
      setIsLoading(false);
   };

   useEffect(() => {
      initAuth();
   }, []);

   if (isLoading) {
      return <LoadingScreen />;
   }
   return children;
}

Auth.propTypes = {
   children: PropTypes.any,
};

export default Auth;
