import "./assets/css/App.css";
import Routes from "./Routes";
import { Router } from "react-router";
import { createBrowserHistory } from "history";
import { ThemeProvider } from "@material-ui/core";
import theme from "./theme";
import Auth from "src/components/Auth";
import { SnackbarProvider } from "notistack";
import { HelmetProvider } from "react-helmet-async";
import { MuiPickersUtilsProvider } from "@material-ui/pickers";
import MomentUtils from "@date-io/moment";
import AccountContextProvider from "./context/AccountContextProvider";

const history = createBrowserHistory();

function App() {
   return (
      <Router history={history}>
         <ThemeProvider theme={theme}>
            <MuiPickersUtilsProvider utils={MomentUtils}>
               <SnackbarProvider maxSnack={3}>
                  <HelmetProvider>
                     <AccountContextProvider>
                        <Auth>
                           <Routes />
                        </Auth>
                     </AccountContextProvider>
                  </HelmetProvider>
               </SnackbarProvider>
            </MuiPickersUtilsProvider>
         </ThemeProvider>
      </Router>
   );
}

export default App;
