import Config from "src/constants/Config";

let IPAddress = Config.IPAddress + "/auth";

const AccountServices = {
   sendOTP: IPAddress + "/sendOTP",
   verifyOTP: IPAddress + "/verifyOTP",
};

export default AccountServices;
