import Axios from "src/config/Axios";
import jwtDecode from "jwt-decode";

class AuthService {
   getAccessToken = async () => JSON.parse(localStorage.getItem("accessToken"));

   getAccount = async () => JSON.parse(localStorage.getItem("account"));

   setSession = (accessToken, account) => {
      if (accessToken) {
         localStorage.setItem("accessToken", JSON.stringify(accessToken));
         localStorage.setItem("account", JSON.stringify(account));

         //  set Axios intercepter
         Axios.defaults.headers.common.Authorization = `Bearer ${accessToken}`;
         Axios.defaults.headers.post["Content-Type"] = "application/json";
         //  Axios.defaults.withCredentials = true;
      } else {
         localStorage.removeItem("accessToken");
         localStorage.removeItem("account");
         delete Axios.defaults.headers.common.Authorization;
      }
   };

   isValidToken = (accessToken) => {
      if (!accessToken) {
         return false;
      }
      const decoded = jwtDecode(accessToken);
      const currentTime = Date.now() / 1000;
      return decoded.exp > currentTime;
   };
   isAuthenticated = () => !!this.getAccessToken();

   logout = () => {
      // TODO: Hit logout api here
      this.setSession(null, null);
   };
}

export default new AuthService();
