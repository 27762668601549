import { createContext, useReducer } from "react";
import { ownerListReducer } from "src/reducers/ownerListReducer";

export const initialState = {
   owners: [],
   showAllOwners: false,
   isLoading: false,
   error: false,
};

export const OwnerListContext = createContext();

const OwnerListContextProvider = ({ children }) => {
   const [ownersList, dispatch] = useReducer(ownerListReducer, initialState);

   return (
      <OwnerListContext.Provider value={{ ownersList, dispatch }}>
         {children}
      </OwnerListContext.Provider>
   );
};

export default OwnerListContextProvider;
