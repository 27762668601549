import Axios from "src/config/Axios";
import AccountServices from "src/services/AccountServices";
import UtilsAuthServices from "src/services/utils/UtilsAuthServices";

export const SET_ACCOUNT = "@account/set-account";
export const REMOVE_ACCOUNT = "@account/remove-account";

export function sendOTP(mobileNumber, userTypeCode) {
   let request = Axios.post(AccountServices.sendOTP, {
      mobileNumber,
      userTypeCode,
   });
   return request
      .then((result) => {
         return result.data;
      })
      .catch((error) => {
         return error.response.data;
      });
}

export function verifyOTP(data) {
   let request = Axios.post(AccountServices.verifyOTP, {
      ...data,
   });
   return request
      .then((result) => {
         return result.data;
      })
      .catch((error) => {
         return error.response.data;
      });
}

export function loginWithToken() {
   let request = Axios.get(UtilsAuthServices.loginWithToken);
   return request
      .then((result) => {
         return result.data;
      })
      .catch((error) => {
         return error.response.data;
      });
}
