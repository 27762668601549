import { createMuiTheme, colors } from "@material-ui/core";
import { createTheme } from "@material-ui/core";
import shadows from "./shadows";
import typography from "./typography";

const theme = createTheme({
   palette: {
      background: {
         dark: "#F4F6F8",
         default: colors.common.white,
         paper: colors.common.white,
      },
      primary: {
         main: colors.indigo[500],
      },
      secondary: {
         main: colors.orange[500],
      },
      orange: {
         main: colors.orange[500],
      },
      text: {
         primary: colors.blueGrey[900],
         secondary: colors.blueGrey[600],
      },
   },
   shadows,
   typography,
});

export default theme;
