const Config = {
   devAPIURL: process.env.REACT_APP_DEV_API_URL,
   prodAPIURL: process.env.REACT_APP_PROD_API_URL,
   showHome: process.env.REACT_APP_SHOW_HOME === "true" ? true : false,
   IPAddress:
      process.env.REACT_APP_PROD_API_URL !== ""
         ? process.env.REACT_APP_PROD_API_URL
         : process.env.REACT_APP_DEV_API_URL,
};

export default Config;
