import { useState } from "react";
import { Link as RouterLink } from "react-router-dom";
import clsx from "clsx";
import PropTypes from "prop-types";
import {
   AppBar,
   Badge,
   Box,
   Hidden,
   IconButton,
   Toolbar,
   makeStyles,
   Typography,
} from "@material-ui/core";
import MenuIcon from "@material-ui/icons/Menu";
import Logo from "src/components/Logo";
import { FiLogOut as LogOutIcon } from "react-icons/fi";

const useStyles = makeStyles(() => ({
   root: {},
   avatar: {
      width: 60,
      height: 60,
   },
   logoutText: {
      marginLeft: 7,
   },
   link: {
      display: "flex",
      alignItems: "center",
      color: "#fff",
      textDecoration: "none",
   },
}));

const TopBar = ({ className, onMobileNavOpen, ...rest }) => {
   const classes = useStyles();

   return (
      <AppBar className={clsx(classes.root, className)} elevation={0} {...rest}>
         <Toolbar>
            <RouterLink to="/admin/dashboard" className={classes.link}>
               <Logo />
               <Box ml={2}>
                  <Typography variant="h4" noWrap>
                     SNGI Admin
                  </Typography>
               </Box>
            </RouterLink>
            <Box flexGrow={1} />
            <Hidden mdDown>
               <IconButton color="inherit" aria-label="logout">
                  <RouterLink to="/logout" className={classes.link}>
                     <LogOutIcon size={20} />
                     <Typography varient="h6" className={classes.logoutText}>
                        Logout
                     </Typography>
                  </RouterLink>
               </IconButton>
            </Hidden>
            <Hidden lgUp>
               <IconButton color="inherit" onClick={onMobileNavOpen}>
                  <MenuIcon />
               </IconButton>
            </Hidden>
         </Toolbar>
      </AppBar>
   );
};

TopBar.propTypes = {
   className: PropTypes.string,
   onMobileNavOpen: PropTypes.func,
};

export default TopBar;
